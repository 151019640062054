import React from "react";
import shape01 from "../../assets/img/logo/coin.jpg";
import shape02 from "../../assets/img/banner/presentation.svg";
import shape03 from "../../assets/img/banner/safebox.svg";
import headerVideo from "../../assets/video/header-vid.mp4";
import fireIcon from "../../assets/img/icon/fire.png";
import CountDownOne from "../CountDown/CountDownOne";

const Banner = (props) => {
  return (
    <section className="banner-area banner-bg">
      <video className="header-video" autoPlay loop muted playsInline>
        <source src={headerVideo} type="video/mp4" />
      </video>
      <span className="mask"></span>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              {/* <img src={fireIcon} alt="" /> */}
              <h2 className="title">
              Join the Future <br/> with <span>CRTZ Coin</span>
              </h2>
            </div>
            <div className="banner-progress-wrap">
              <ul>
                <li>Pre Sell</li>
                <li>Soft Cap</li>
                <li>Bonus</li>
              </ul>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "75%" }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <h4 className="title">
                65% target raised <span>1 ETH = $2400 = 3177.38 CTZ</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">ICO Will Start In..</h2>

              <CountDownOne />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
