import React from "react";
import downloadImg from "../../assets/img/coin.png";
import aboutImg from "../../assets/img/images/h2_about_img.png";

import downloadBtn01 from "../../assets/img/images/download_btn01.png";
import downloadBtn02 from "../../assets/img/images/download_btn02.png";

const DownloadArea = () => {
  return (
    <section className="download-area pt-100 pb-140">
      <div className="container custom-container-four">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
            <div
              className="download-img text-center wow fadeInRight"
              data-wow-delay=".2s"
            >
              <img className="w-75" src={downloadImg} alt="" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div
              className="download-content wow fadeInLeft"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-20">
                <span className="sub-title">How to Get Involved</span>
                <h2 className="title text-light">
                  We invite you to join our growing community 
                </h2>
              </div>
              <p>
              By holding CTZ, you’re not only investing in the future of decentralized finance but also actively contributing to global initiatives that matter. Whether you’re an individual investor, a business, or a non-profit looking to collaborate, CTZ offers a unique opportunity to merge financial growth with social good.
              </p>
              <a
                href="#"
                download
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                Get Involved
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadArea;
