import React from "react";
import img01 from "../../assets/img/blog/rc_post_img01.jpg";
import img02 from "../../assets/img/blog/rc_post_img02.jpg";
import img03 from "../../assets/img/blog/rc_post_img03.jpg";
import img04 from "../../assets/img/blog/rc_post_img04.jpg";
import BlogRecentItem from "./BlogRecentItem";

const BlogRecents = () => {
  const recentItems = [
    {
      title: "Don’t Underestimate The Power",
      url: "/blog",
      time: "4 Oct. 2024",
      src: "https://images.unsplash.com/photo-1639728758333-1deb626aab8c?q=80&w=2972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      title: "Human-Machine Interfaces",
      url: "/blog",
      time: "4 Nov. 2024",
      src: "https://images.unsplash.com/photo-1652151368404-d9b0e7713bdb?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      title: "Web3 App Done Well: Excellent",
      url: "/blog",
      time: "4 Dev. 2024",
      src: "https://plus.unsplash.com/premium_photo-1673208585727-f1025177f171?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ];

  return (
    <div className="blog-widget">
      <h2 className="bw-title">Recent Post</h2>
      <div className="rc-post-wrap">
        {recentItems.map((item, index) => (
          <BlogRecentItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default BlogRecents;
