import React from "react";
import BlogListItem from "./BlogListItem";

const BlogList = () => {
  const blog_list = [
    {
      url: "/blog/future-of-crypto",
      src: "https://interexy.com/wp-content/uploads/2022/09/5250-1.png",
      author: "Alextina",
      time: "Oct 08, 2024",
      comments: "03",
      title:
        "The Future of Cryptocurrency: Combining Financial Innovation with Social Impact",
      summary:
        "One of the most compelling aspects of blockchain is its ability to provide complete transparency and traceability. Every transaction is recorded on an immutable ledger, meaning contributions made through cryptocurrencies can be verified in real-time. ",
    },
    {
      url: "/blog-details",
      src: "https://images.unsplash.com/photo-1672912464993-f211ad866293?q=80&w=3132&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      author: "Alextina",
      time: "Oct 28, 2024",
      comments: "03",
      title: (
        <>
          That Time We Burned Down Players’ <br />
          Houses in Ultima Online
        </>
      ),
      summary:
        "Laboratories used for scientific research take many forms because of the differing requirements of specialists in the various fields of science and engineering. A physics laboratory",
    },
    {
      url: "/blog-details",
      src: "https://images.unsplash.com/photo-1666979663115-4764b4affa0e?q=80&w=3132&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      author: "Alextina",
      time: "Oct 28, 2024",
      comments: "03",
      title: `Why Your Next ‘Yes’ Hire Might Come From Someone Who Just Said`,
      summary:
        "Laboratories used for scientific research take many forms because of the differing requirements of specialists in the various fields of science and engineering. A physics laboratory",
    },
  ];

  return (
    <>
      {blog_list.map((blog, index) => (
        <BlogListItem key={index} item={blog} />
      ))}
    </>
  );
};

export default BlogList;
