import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Mid of Q4 2024",
      title: "Concept Development",
      info: [
        "• Develop the foundational concept of CurtezanCoin and outline tokenomics.",
        "• Launch the official website and begin community engagement via social media.",
        "• Develop partnerships with initial adult entertainment platforms for pilot integration.",
      ],
    },
    {
      roadmapTitle: "Mid of Q1 2025",
      title: "Token Creation",
      info: [
        "• Build and deploy the CTZ token on the blockchain (ERC-20).",
        "• Token pre-sale to raise funds for development.",
        "• Coordinator and client SDK",
      ],
    },

    {
      roadmapTitle: "Mid of Q2 2025",
      title: "Platform Development & Beta Launch",
      info: [
        "• Complete platform development with key features (wallet integration, content payment solutions, smart contracts for creators).",
        "• Public token sale and listing on major decentralized exchanges (DEX).",
      ],
    },

    {
      roadmapTitle: "Mid of Q3 2025",
      title: "Token Test",
      info: [
        "• Beta testing with partner platforms and creators.",
        "• Integration of key adult entertainment services for pilot testing.",
        "• Launch the reward system for users and creators.",
      ],
    },
    {
      roadmapTitle: "Mid of Q4 2025",
      title: "Full Launch",
      info: [
        "• Full-scale platform launch with global adult content providers.",
        "• Begin integration with wallet services and crypto payment processors.",
        "• Extensive marketing campaigns targeting content creators, consumers, and adult platforms.",
      ],
    },
    {
      roadmapTitle: "Mid of Q1 2026",
      title: "Marketing",
      info: [
        "• Expand partnerships to international platforms.",
        "• Establish partnerships with major content providers, including subscription-based and camming services.",
        "• Explore global expansion and entry into new markets.",
      ],
    },
    {
      roadmapTitle: "Mid of Q2 2026",
      title: "Expansion & Ecosystem Growth",
      info: [
        <>
          • Integrate staking, enabling CTZ holders to earn more.
        </>,
        "• Continue platform optimization and introduce new features based on community feedback.",
      ],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-md-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-md-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
                Curtezan Strategy and <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
