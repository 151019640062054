import React from "react";
import BlogCommentForm from "../../components/BlogDetails/BlogCommentForm";
import BlogComments from "../../components/BlogDetails/BlogComments";
import FutureOfCrypto from "../../components/BlogDetails/FutureOfCrypto";
import LayoutBlog from "../../layouts/LayoutBlog";

const FutureOfCryptoPost = () => {
  return (
    <LayoutBlog pageTitle={"Future Of Cryptocurrency"} item={"Future of Crypto"}>
      <FutureOfCrypto />
      <BlogComments />
      <BlogCommentForm />
    </LayoutBlog>
  );
};

export default FutureOfCryptoPost;
