import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.png";
import $ from "jquery";

const HeaderOne = () => {
  const [stickyClass, setStickyClass] = useState({ fixed: "", header: "" });
  const { hash, pathname } = useLocation();

  // Sticky navbar logic
  const stickNavbar = () => {
    const windowHeight = window.scrollY;
    setStickyClass({
      fixed: windowHeight < 245 ? "" : "active-height",
      header: windowHeight < 245 ? "" : "sticky-menu",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // Close mobile menu function
  const closeMobileMenu = () => {
    $("body").removeClass("mobile-menu-visible");
  };

  // Mobile menu setup
  useEffect(() => {
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    if ($(".mobile-menu").length) {
      const mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on("click", function () {
        $(this).toggleClass("open");
        $(this).prev("ul").slideToggle(500);
      });

      $(".mobile-nav-toggler").on("click", () => {
        $("body").addClass("mobile-menu-visible");
      });

      $(".menu-backdrop, .mobile-menu .close-btn").on("click", closeMobileMenu);
      $(".mobile-menu .menu-box .menu-outer a").on("click", closeMobileMenu);
    }
  }, []);

  // Scroll to section when hash changes
  useEffect(() => {
    if (hash) {
      const targetElement = document.getElementById(hash.replace("#", ""));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [hash]);

  // Check if link is active based on hash
  const isActiveLink = (id) => (id === hash ? "active" : "");

  return (
    <header id="header">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />

      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo d-flex align-items-center">
                    <Link to="/">
                      <img src={logoImage} alt="Curtezan Logo" />
                    </Link>
                    <h5 className="text-white fs-3 ms-2">Curtezan</h5>
                  </div>

                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li className={cn(hash === "" && "active")}>
                        <Link to="/" className="section-link">Home</Link>
                      </li>
                      <li className={isActiveLink("#about")}>
                        <Link to="/about" className="section-link">About us</Link>
                      </li>
                      <li className={isActiveLink("#sales")}>
                        <Link to="/#sales" className="section-link">Sales</Link>
                      </li>
                      <li className={isActiveLink("#roadmap")}>
                        <Link to="/#roadmap" className="section-link">Roadmap</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li className={isActiveLink("#contact")}>
                        <Link to="/#contact" className="section-link">Contact us</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-btn">
                        <Link to="#" className="btn">Buy Now</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* Mobile Menu */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src={logoImage} alt="Curtezan Logo" />
                    </Link>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-instagram" /></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                      <li><a href="#"><i className="fab fa-youtube" /></a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;