import React from "react";
import LayoutOne from "../../layouts/LayoutOne";
import BannerAbout from "../../components/About/BannerAbout";
import AboutKey from "../../components/About/AboutUs";
import GetInvolved from "../../components/About/GetInvolved"
import Newsletter from "../../components/Newsletter/Newsletter";

const AboutUs = () => {
    return (
    <LayoutOne>
      <main className="fix">
        <BannerAbout/>
        <div className="container custom-container-four">
          <div className="row align-items-center">
            <div className="col-lg-9 order-0 order-lg-2">
            <h1 className="mt-5" id="about-us">About Us </h1>
          <div className="mb-4">
            <p className="text-light">At CTZ we are redefining the potential of cryptocurrency by merging decentralized finance (DeFi) with tangible, real-world impact. Our vision goes beyond just offering financial freedom through blockchain technology — we aim to harness the power of crypto transactions to drive meaningful social change.</p>
          </div>
            </div>
          </div>
        <div className="row pb-5">
          <div className="col-lg-6">
            <h4 className="mt-5 mb-3">Our Mission: <br/><span className="opacity-75">Transforming Finance for Global Good </span></h4>
            <p className="text-light opacity-75">We believe in the transformative power of cryptocurrency to do more than create wealth. By integrating a social responsibility component into our tokenomics, we ensure that every transaction on our platform contributes to causes that matter. Our mission is simple yet powerful: create a decentralized, transparent financial ecosystem that empowers individuals while supporting global NGOs working on critical issues such as human trafficking.</p>
          </div>
          <div className="col-lg-6">
            <h4 className="mt-5 mb-3">Technology: <br/><span className="opacity-75">Secure, Scalable, and Transparent</span></h4>
            <p className="text-light opacity-75">CTZ is built on a highly secure, scalable blockchain infrastructure, ensuring fast and efficient transactions with minimal fees. The foundation of our platform lies in the use of smart contracts, which automatically execute predefined actions — such as allocating a percentage of each transaction to selected NGOs — without the need for third-party intermediaries.</p>
          </div>
        </div>
        </div>


        <div>
          <AboutKey/>
          <GetInvolved/>




       
        </div>
        



        <h2 className="text-center mb-5">Ready to Make a Difference?</h2>

      </main>
    </LayoutOne>
    )
}

export default AboutUs;