import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import icon01 from "../../assets/icons/privacy.svg";
import icon02 from "../../assets/icons/impact.png";
import icon03 from "../../assets/icons/secured.png";
import icon04 from "../../assets/icons/community.svg";
import icon05 from "../../assets/icons/reach.png";
import icon06 from "../../assets/icons/decentralised.png";
import icon07 from "../../assets/icons/chargeback.png";
import icon08 from "../../assets/icons/systems.png";
import WhyChooseUsItem from "./WhyChooseUsItem";

const WhyChooseUs = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      src: icon01,
      alt: "",
      link: "/",
      title: "Privacy & Anonymity",
      description: "Our token ensures secure, anonymous transactions, protecting both consumers and content creators in an industry where privacy is paramount.",
    },
    {
      src: icon02,
      alt: "",
      link: "/",
      title: "Purpose-Driven Impact",
      description: "We combine cutting-edge blockchain technology with a social mission, redirecting a portion of every transaction to support global NGOs against human traficking.",
    },
    {
      src: icon03,
      alt: "",
      link: "/",
      title: "Secure Payment Ecosystem",
      description: "Built on blockchain, every transaction is encrypted and immutable, ensuring safety for all parties involved in the adult industry.",
    },
    {
      src: icon04,
      alt: "",
      link: "/",
      title: "Community-Focused",
      description: "Our platform empowers token holders to vote on charitable allocations, fostering a decentralized and engaged community.",
    },
    {
      src: icon05,
      alt: "",
      link: "/",
      title: "Global Reach",
      description: "Our token is borderless, allowing users and creators from any country to engage in transactions without facing currency conversion issues or geographical restrictions. We partner with NGOs across multiple sectors.",
    },
    {
      src: icon06,
      alt: "",
      link: "/",
      title: "Decentralized Platform",
      description: "No central authority controls the transactions, meaning there's no risk of account freezes or denial of service that often plagues the adult industry.",
    },
    {
      src: icon07,
      alt: "",
      link: "/",
      title: "Protection from Chargebacks",
      description: "Our token’s blockchain transactions are irreversible, protecting content creators from fraudulent chargebacks that are common in the adult industry.",
    },
    {
      src: icon08,
      alt: "",
      link: "/",
      title: "Multi-Platform Integration",
      description: "Our token can be integrated with existing adult websites, apps, and platforms, making it versatile and adaptable to various business models.",
    },
    
    
  ];

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((old) => (old === 6 ? 0 : old + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const toMultiply = 100 / slider_items.length;

  return (
    <section className="choose-area pb-130">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-8 col-lg-6">
            <div className="section-title text-md-center mb-50 mt-50">
              <span className="sub-title">why Choose us </span>
              <h2 className="title">
                Why choose our Curtezan <span>Token</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row choose-active">
          {/* <SlickSlider settings={slickSettings}> */}
            {slider_items.map((item, index) => (
              <div key={index} className="col-lg-3">
                <WhyChooseUsItem item={item} />
              </div>
            ))}
          {/* </SlickSlider> */}
        </div>

        {/* <div className="slide-progressbar">
          <div
            style={{
              // width: `${count * toMultiply}%`,
              backgroundSize: `${count * toMultiply}% 100%`,
            }}
            className="slide-filler"
          />
        </div> */}

        {/* <div
          className="slide-progress"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span className="slider__label sr-only"></span>
        </div> */}
      </div>
    </section>
  );
};

export default WhyChooseUs;
