import React from "react";
import icon01 from "../../assets/img/icon/h2_choose_icon01.svg";
import icon02 from "../../assets/img/icon/h2_choose_icon02.svg";
import icon03 from "../../assets/img/icon/h2_choose_icon03.svg";
import icon04 from "../../assets/img/icon/h2_choose_icon04.svg";
import WhyChooseUsTwoItem from "./AboutUsItem";

const WhyChooseUsTwo = () => {
  const items = [
    {
      src: icon03,
      title: "Smart Contract Automation",
      desc: "Every time a transaction is made, a smart contract seamlessly distributes a predetermined percentage of the transaction value to our charity pool. This ensures continuous support for selected causes, without any manual intervention.",
      url: "/home-two",
    },
    {
      src: icon04,
      title: "Blockchain Transparency",
      desc: "One of the core strengths of blockchain technology is its ability to provide complete transparency. All donations made through CTZ are publicly traceable, giving our users peace of mind and trust in the system.",
      url: "/home-two",
    },
    {
      src: icon02,
      title: "Community Governance",
      desc: "Through our governance model, token holders are given the power to vote on key decisions, including which NGOs or charitable projects will receive funding. This decentralized approach ensures that our community has a direct say in how social impact is created, further reinforcing trust and participation.",
      url: "/home-two",
    },
    {
      src: icon01,
      title: "Social Impact - Making Every Token Count",
      desc: "We are not just another cryptocurrency. CTZ integrates a built-in social impact mechanism that allows us to contribute to high-impact projects around the world. A percentage of every transaction automatically supports NGOs working in critical areas such as:",
      url: "/home-two",
    },
    {
      src: icon01,
      title: "Human Rights and Anti-Trafficking",
      desc: "Partnering with organizations committed to fighting human trafficking and ensuring human rights globally.",
      url: "/home-two",
    },
    {
      src: icon01,
      title: "Transparency and Accountability",
      desc: "Our platform provides real-time tracking of every charitable contribution, ensuring full accountability and allowing users to see the direct impact of their transactions.",
      url: "/home-two",
    },
  ];

  return (
    <section className="choose-area-two choose-bg pb-130">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-70">
              {/* <span className="sub-title">why Choose us</span> */}
              <h2 className="title">Key Technical Features</h2>
            </div>
          </div>
        </div>

        <div className="row">
          {items.map((x, index) => (
            <div key={index} className="col-lg-4 col-sm-6">
              <WhyChooseUsTwoItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsTwo;
